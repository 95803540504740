import { Carousel, Col, Row } from "react-bootstrap";

//images
import comprovante_bertolini from "../../../../Assets/Images/comprovante_bertolini.png";
import comprovante_ldc from "../../../../Assets/Images/comprovante_ldc.png";
import comprovante_rumo from "../../../../Assets/Images/comprovante_rumo.png";
import { ImgContainer, SliderContainer } from "./styles";

const ReceiptSelectorSlider = ({ index, setIndex, selectedReceiptModel }) => {
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <SliderContainer>
        <Carousel
          variant="dark"
          activeIndex={index}
          onSelect={handleSelect}
          interval={null}
          controls={selectedReceiptModel === ""}
          indicators={selectedReceiptModel === ""}
          style={{ height: "340px" }}
        >
          <Carousel.Item>
            <ImgContainer>
              <img alt="comprovante_bertolini" src={comprovante_bertolini} />
            </ImgContainer>
          </Carousel.Item>

          <Carousel.Item>
            <ImgContainer>
              <img alt="comprovante_ldc" src={comprovante_ldc} />
            </ImgContainer>
          </Carousel.Item>

          <Carousel.Item>
            <ImgContainer>
              <img alt="comprovante_rumo" src={comprovante_rumo} />
            </ImgContainer>
          </Carousel.Item>
        </Carousel>
      </SliderContainer>
    </>
  );
};

export default ReceiptSelectorSlider;
