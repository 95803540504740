import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

import ApiRequest from "../Shared/Utils/Request";

export const ManifestContext = createContext({
  manifesties: [],
  manifest: null,
  hashManifest: [],
  manifestValidation: null,
  totalPage: 1,
  isLoading: false,
  isVerifyImgLoading: false,
  hasError: false,
  errorResult: false,
  verifyBarcodeError: null,
  getManifest: () => null,
  getManifesties: () => null,
  geHashManifest: () => null,
  postHasManifest: () => null,
  postNotify: () => null,
  getVerifyBarcode: () => null,
  postManifestValidation: () => null,
  getVerifyNumberVehicle: () => null,
  postValidateManifestImage: () => null,
  errorValidateImage: false,
  setErrorValidateImage: () => null,
});

export const useManifest = () => {
  const context = useContext(ManifestContext);

  if (!context) {
    throw new Error("useManifest must be within ManifestProvider");
  }

  return context;
};

export const ManifestProvider = ({ children }) => {
  const [manifesties, setManifesties] = useState([]);
  const [manifest, setManifest] = useState(null);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifyImgLoading, setIsVerifyImgLoading] = useState(false);

  const [hasError, setHasError] = useState(false);
  const [manifestValidation, setManifestValidation] = useState(null);

  const [hashManifest, setHashManifest] = useState([]);
  const [errorResult, setErrorResult] = useState(false);
  const [errorValidateImage, setErrorValidateImage] = useState(null);
  const [verifyBarcodeError, setVerifyBarcodeError] = useState(null);

  const getManifesties = useCallback(async (currentPage, data) => {
    try {
      setIsLoading(true);
      const params = {
        perPage: 20,
        page: currentPage,
        ...data,
      };

      const response = await ApiRequest({
        path: "manifestos",
        method: "GET",
        params,
      });

      setManifesties(response?.data?.data);
      setTotalPage(response?.data?.total_page);
    } catch (error) {
      setManifesties([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getManifest = useCallback(async (id) => {
    try {
      setIsLoading(true);

      const response = await ApiRequest({
        path: `manifestos/${id}`,
        method: "GET",
      });

      setManifest(response?.data);
    } catch (error) {
      setManifest(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const geHashManifest = useCallback(async (hash) => {
    try {
      setIsLoading(true);

      const response = await ApiRequest({
        path: `manifestos-hash/${hash}`,
        method: "GET",
      });

      if (response?.data?.conhecimentos) {
        setHashManifest(response?.data);
        setHasError(false);
      } else {
        setHashManifest([]);
        setHasError(true);
      }
    } catch (error) {
      setHashManifest([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getVerifyBarcode = useCallback(async (id, barcode) => {
    try {
      setIsLoading(true);

      const response = await ApiRequest({
        path: `verify-cte/${id}/${barcode}`,
        method: "GET",
      });

      !response?.data?.success &&
        setVerifyBarcodeError(
          "O código de barras informado não pertence a este MDF-e"
        );

      response?.data?.success &&
        response?.data?.conhec_docs_anexados &&
        setVerifyBarcodeError("O comprovante deste CT-e já foi anexado");

      return response?.data;
    } catch (error) {
      setVerifyBarcodeError("Erro ao verificar código de barras");
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getVerifyNumberVehicle = useCallback(
    async (id, number_cte, vehicle_plates) => {
      try {
        setIsLoading(true);

        const response = await ApiRequest({
          path: `verify-cte-numero `,
          method: "POST",
          data: {
            manif_cod: id,
            conhec_numero: number_cte,
            placa: vehicle_plates,
          },
        });

        !response?.data?.success &&
          setVerifyBarcodeError(
            "O número CT-e e placa informados não pertencem a este MDF-e"
          );

        response?.data?.success &&
          response?.data?.conhec_docs_anexados &&
          setVerifyBarcodeError("O comprovante deste CT-e já foi anexado");

        return response?.data;
      } catch (error) {
        setVerifyBarcodeError("Erro ao verificar número CT-e e placa");
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const postValidateManifestImage = useCallback(
    async (data, imgType, setActiveStep) => {
      if (imgType === "rumo") {
        try {
          setHasError(false);
          setIsVerifyImgLoading(true);

          const response = await ApiRequest({
            path: `validate-manifesto-image-rumo`,
            method: "POST",
            data,
          });

          if (response.data?.status === "pass") {
            setErrorValidateImage(null);
            setActiveStep(4);
          }
        } catch (error) {
          if (error?.response?.data?.status === "fail") {
            setErrorValidateImage(
              "O comprovante de descarga 'RUMO' anexado não foi reconhecido."
            );
          } else {
            setErrorValidateImage("Erro ao validar imagem");
          }
        } finally {
          setIsVerifyImgLoading(false);
        }
      } else if (imgType === "ldc") {
        try {
          setHasError(false);
          setIsVerifyImgLoading(true);

          const response = await ApiRequest({
            path: `validate-manifesto-image-ldc`,
            method: "POST",
            data,
          });

          if (response.data?.status === "pass") {
            setErrorValidateImage(null);
            setActiveStep(4);
          }
        } catch (error) {
          if (error?.response?.data?.status === "fail") {
            setErrorValidateImage(
              "O comprovante de descarga 'LDC' anexado não foi reconhecido."
            );
          } else {
            setErrorValidateImage("Erro ao validar imagem");
          }
        } finally {
          setIsVerifyImgLoading(false);
        }
      } else {
        try {
          setHasError(false);
          setIsVerifyImgLoading(true);

          const response = await ApiRequest({
            path: `validate-manifesto-image-bertolini`,
            method: "POST",
            data,
          });

          if (response.data?.status === "pass") {
            setErrorValidateImage(null);
            setActiveStep(4);
          }
        } catch (error) {
          if (error?.response?.data?.status === "fail") {
            setErrorValidateImage(
              "O comprovante de descarga 'BERTOLINI' anexado não foi reconhecido."
            );
          } else {
            setErrorValidateImage("Erro ao validar imagem");
          }
        } finally {
          setIsVerifyImgLoading(false);
        }
      }
    },
    []
  );

  const postHasManifest = useCallback(
    async (barcode, data, setActiveStep) => {
      try {
        setHasError(false);
        setIsLoading(true);

        await ApiRequest({
          path: `manifestos/doc-cte/${barcode}`,
          method: "POST",
          data,
        });

        await geHashManifest(data?.conhec_hash_motorista);

        setActiveStep(5);

        window.scrollTo(0, 0);
      } catch (error) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [geHashManifest]
  );

  const postNotify = useCallback(async (id) => {
    try {
      setIsLoading(true);

      await ApiRequest({
        path: `/manifestos/resend-notify/${id}`,
        method: "POST",
      });

      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Link enviado para o whatsapp do motorista com sucesso",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass: {
          popup: "swal2-noanimation",
          backdrop: "swal2-noanimation",
        },
        hideClass: {
          popup: "",
          backdrop: "",
        },
      });
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const postManifestValidation = useCallback(async (image) => {
    try {
      setIsLoading(true);
      setErrorResult(false);

      const response = await ApiRequest({
        path: "manifestos-image",
        method: "POST",
        data: { image },
      });

      setManifestValidation(response?.data);
      return response?.data;
    } catch (error) {
      console.error({ error });
      setManifestValidation(null);
      setErrorResult(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const providerValue = useMemo(
    () => ({
      manifesties,
      manifest,
      hashManifest,
      totalPage,
      isLoading,
      hasError,
      manifestValidation,
      errorResult,
      verifyBarcodeError,
      getManifest,
      getManifesties,
      geHashManifest,
      postHasManifest,
      postNotify,
      getVerifyBarcode,
      postManifestValidation,
      setErrorResult,
      setVerifyBarcodeError,
      getVerifyNumberVehicle,
      postValidateManifestImage,
      errorValidateImage,
      setErrorValidateImage,
      isVerifyImgLoading,
    }),
    [
      manifesties,
      manifest,
      hashManifest,
      totalPage,
      isLoading,
      hasError,
      manifestValidation,
      errorResult,
      verifyBarcodeError,
      getManifest,
      getManifesties,
      geHashManifest,
      postHasManifest,
      postNotify,
      getVerifyBarcode,
      postManifestValidation,
      setErrorResult,
      setVerifyBarcodeError,
      getVerifyNumberVehicle,
      postValidateManifestImage,
      errorValidateImage,
      setErrorValidateImage,
      isVerifyImgLoading,
    ]
  );

  return (
    <ManifestContext.Provider value={providerValue}>
      {children}
    </ManifestContext.Provider>
  );
};
